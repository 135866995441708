
import { Component, Vue } from "vue-property-decorator";
import SendTicketDateForm from "@/components/forms/SendTicketDateForm.vue";
import FormCard from "@/components/FormCard.vue";

@Component({
  components: {
    SendTicketDateForm,
    FormCard
  }
})
export default class SendRequest extends Vue {
  private mounted() {
    this.$gtag.event("Envío de la solicitud", {
      event_label: "Envío de la solicitud",
      event_category: "test",
      value: 1
    });
    window.scrollTo(0, 0);
  }
}
